<template>
  <div class="course-edit pb-5">
    <v-card flat tile min-height="100" class="course-edit-header gold d-flex align-center justify-center">
      <v-card tile flat max-width="400" width="100%" class="primary white--text h1 py-1 text-center">{{ data.title }}</v-card>
    </v-card>
    <div class="wrap mt-n5">
      <v-card flat class="pa-6 mb-4">
        <form>
          <div class="d-flex justify-space-between align-center mb-4">
            <div class="h4">Course description</div>
            <v-switch
              class="mt-0"
              @change="activate"
              color="secondary"
              v-model="switch1"
              inset
              :label="switch1 ? 'Active' : 'Inactive'"
              hide-details
            ></v-switch>
          </div>
          <div class="h11">Title<span class="error--text">*</span></div>
          <v-text-field
            class="field44"
            v-model="data.title"
            :error-messages="titleErrors"
            placeholder="Enter title course"
            outlined
            dense
            :clearable="data.status == 'draft'"
            clear-icon="mdi-close-circle"
            color="secondary"
            :readonly="data.status != 'draft'"
            height="44"
          ></v-text-field>
          <div class="h11 mt-n3">Short description<span class="error--text">*</span></div>
          <v-text-field
            class="field44"
            v-model="data.short_description"
            :error-messages="shortErrors"
            placeholder="Enter short description course"
            outlined
            dense
            :clearable="data.status == 'draft'"
            clear-icon="mdi-close-circle"
            color="secondary"
            :readonly="data.status != 'draft'"
            height="44"
          ></v-text-field>
          <div class="d-flex mt-n3 align-center">
            <div class="h11">Description<span class="error--text">*</span></div>
            <v-spacer></v-spacer>
            <div class="text--text" style="font-size: 9px">Up to 600 symb.</div>
          </div>
          <v-textarea
            v-model="data.description"
            :error-messages="descErrors"
            placeholder="Enter description course"
            outlined
            dense
            counter
            :clearable="data.status == 'draft'"
            clear-icon="mdi-close-circle"
            color="secondary"
            auto-grow
            :readonly="data.status != 'draft'"
            maxlength="600"
          ></v-textarea>
          <div class="h11 mt-n3">Category<span class="error--text">*</span></div>
          <v-select
            class="field44"
            v-model="data.category"
            :items="categoriesData"
            :error-messages="categoryErrors"
            placeholder="Select category"
            append-icon="mdi-chevron-down mdi-18px"
            outlined
            dense
            item-text="title"
            item-value="id"
            return-object
            color="secondary"
            :readonly="data.status != 'draft'"
            height="44"
          ></v-select>
          <v-row>
            <v-col cols="12" sm="6" class="pb-sm-3 pb-0">
              <div class="h11 mt-n3">Level<span class="error--text">*</span></div>
              <v-select
                class="field44 text-capitalize"
                v-model="data.level"
                :items="levelList"
                :error-messages="levelErrors"
                placeholder="Select level"
                append-icon="mdi-chevron-down mdi-18px"
                outlined
                dense
                color="secondary"
                :readonly="data.status != 'draft'"
                height="44"
              >
                <template v-slot:item="data">
                  <span class="text-capitalize">{{ data.item }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" class="pt-sm-3 pt-0">
              <div class="h11 mt-n3">Lesson duration, minutes<span class="error--text">*</span></div>
              <v-select
                class="field44"
                v-model="data.lesson_duration_minutes"
                :items="durationList"
                :error-messages="durationErrors"
                placeholder="Select duration"
                append-icon="mdi-chevron-down mdi-18px"
                outlined
                dense
                color="secondary"
                :readonly="data.status != 'draft'"
                height="44"
              ></v-select>
            </v-col>
          </v-row>
          <div class="text-end">
            <v-btn v-if="data.status == 'draft'" @click="updateCourse" color="primary" width="100" height="34">SAVE</v-btn>
          </div>
        </form>
      </v-card>
      <v-card flat class="pa-6 mb-4 pe-10">
        <div class="h4 mb-3">Options</div>
        <form v-for="(item, i) in data.options" :key="i">
          <div class="h8 mb-1">Option {{ i + 1 }}</div>
          <div v-if="i != 0 || item.id" class="remove-option">
            <v-icon @click="deleteOption(i, item)" class="gray--text">mdi-minus</v-icon>
          </div>
          <v-row class="px-1">
            <v-col cols="12" sm="8" class="pb-0 px-2">
              <div class="h11">Title</div>
              <v-text-field
                class="field44"
                v-model="item.title"
                :error-messages="titleOptionErrors"
                placeholder="Enter title option"
                outlined
                dense
                clearable
                clear-icon="mdi-close-circle"
                color="secondary"
                height="44"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="pb-0 pt-sm-3 pt-0 px-2 mt-sm-0 mt-n3">
              <div class="h11">Type</div>
              <v-select
                class="field44 text-capitalize"
                v-model="item.type"
                :items="typeList"
                :error-messages="typeOptionErrors"
                placeholder="Select type"
                append-icon="mdi-chevron-down mdi-18px"
                outlined
                dense
                color="secondary"
                height="44"
              >
                <template v-slot:item="data">
                  <span class="text-capitalize">{{ data.item }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="4" class="pb-sm-3 pb-0 pt-0 px-2 mt-n3">
              <div class="h11">Number of lessons</div>
              <v-text-field
                class="field44"
                v-model="item.lessons"
                :error-messages="lessonsOptionErrors"
                placeholder="Enter number of lesson"
                outlined
                dense
                clearable
                clear-icon="mdi-close-circle"
                color="secondary"
                height="44"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="pb-sm-3 pb-0 pt-0 px-2 mt-n3">
              <div class="h11">Discount</div>
              <v-text-field
                class="field44"
                v-model="item.discount_percent"
                :error-messages="discountOptionErrors"
                suffix="%"
                placeholder="Enter discount, %"
                outlined
                dense
                clearable
                clear-icon="mdi-close-circle"
                color="secondary"
                height="44"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="pb-sm-3 pb-0 pt-0 px-2 mt-n3">
              <div class="h11">Price</div>
              <v-text-field
                class="field44"
                v-model="item.priceValue"
                :error-messages="priceOptionErrors"
                placeholder="Price, SAR"
                outlined
                dense
                clearable
                clear-icon="mdi-close-circle"
                color="secondary"
                height="44"
              ></v-text-field>
            </v-col>
          </v-row>
        </form>
        <div class="d-flex justify-space-between">
          <v-btn :disabled="!!data.options.find((item) => item.number == 0)" @click="newItem" outlined width="130" height="34">
            <v-icon left>mdi-plus</v-icon> Add option
          </v-btn>
          <div class="text-end">
            <v-btn @click="saveOption" color="primary" width="100" height="34">SAVE</v-btn>
          </div>
        </div>
      </v-card>
      <v-card flat class="pa-6 mb-4">
        <div class="h4 mb-1">Attachments</div>
        <div class="text--text h10 mb-4">Add link to external documents, sites, video or upload photos, document</div>
        <v-menu offset-y nudge-bottom="5">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" outlined width="130" height="34"><v-icon left>mdi-plus</v-icon> Add</v-btn>
          </template>
          <v-list>
            <v-list-item link @click="modalLink = true">
              <v-list-item-icon class="me-3">
                <img src="@/assets/icon/link.svg" />
              </v-list-item-icon>
              <v-list-item-title>Link</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="modalMedia = true">
              <v-list-item-icon class="me-3">
                <img src="@/assets/icon/img.svg" />
              </v-list-item-icon>
              <v-list-item-title>Upload a media</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div :class="data.attachments.length ? 'mt-7' : ''" v-if="data.attachments">
          <div @click="openModal(item)" v-for="item in data.attachments" :key="item.id" class="d-flex align-center mt-4 link">
            <v-img class="me-3 d-flex align-end" max-width="66" v-if="item.type == 'video'" :src="item.preview_url">
              <v-card tile color="black" width="26" height="26" class="ms-auto d-flex align-center justify-center rounded-tl-lg">
                <v-img tile class="filter-white" contain height="16" src="@/assets/icon/edit.svg"></v-img>
              </v-card>
            </v-img>
            <div v-else-if="item.type == 'pdf'" class="me-3">
              <v-img v-if="item.picture" class="d-flex align-end" max-width="66" :src="item.picture">
                <v-card tile color="black" width="26" height="26" class="ms-auto d-flex align-center justify-center rounded-tl-lg">
                  <v-img tile class="filter-white" contain height="16" src="@/assets/icon/edit.svg"></v-img>
                </v-card>
              </v-img>
              <v-card v-else tile flat width="66" height="50" color="#eaeafb" class="d-flex align-center justify-center">
                <img class="d-block" src="@/assets/img/empty-file.svg" />
              </v-card>
            </div>
            <div v-else-if="item.type == 'link'" class="me-3">
              <v-img class="link d-flex align-end" v-if="item.url" height="54" cover :src="item.url">
                <v-card tile color="black" width="26" height="26" class="ms-auto d-flex align-center justify-center rounded-tl-lg">
                  <v-img tile class="filter-white" contain height="16" src="@/assets/icon/edit.svg"></v-img>
                </v-card>
              </v-img>
              <v-card v-else flat tile color="#eaeafb" width="66" height="54" class="d-flex align-center justify-center">
                <img class="d-block" src="@/assets/img/empty-link.svg" />
              </v-card>
            </div>
            <v-img class="me-3 d-flex align-end" min-height="50" max-width="66" v-else :src="item.url">
              <v-card tile color="black" width="26" height="26" class="ms-auto d-flex align-center justify-center rounded-tl-lg">
                <v-img tile class="filter-white" contain height="16" src="@/assets/icon/edit.svg"></v-img>
              </v-card>
            </v-img>
            <div>
              <div class="h7 clip">{{ item.title }}</div>
              <div class="h11 text--text clip2">{{ item.description }}</div>
              <div v-if="item.type == 'link'">
                <a class="url--text h7" :href="item.link" target="_blank">{{ item.link }}</a>
              </div>
            </div>
          </div>
        </div>
      </v-card>
      <div ref="list" :key="key">
        <div
          v-for="(item, i) in data.lessons"
          :key="i"
          ref="item"
          :draggable="data.status == 'draft' && !data.lessons.find((j) => j.number == 0)"
        >
          <v-card flat class="pa-6 my-4">
            <div class="mb-4 d-flex">
              <v-icon v-if="data.status == 'draft' && !data.lessons.find((j) => j.number == 0)" class="me-2 black--text">
                mdi-drag-vertical mdi-18px
              </v-icon>
              <div class="h4">
                Lesson <span id="number" v-if="item.number">{{ item.number }}</span>
              </div>
            </div>
            <div class="h11">Title<span class="error--text">*</span></div>
            <v-text-field
              class="field44"
              v-model="item.title"
              :error-messages="titleErrors"
              placeholder="Enter title lesson"
              outlined
              dense
              :clearable="data.status == 'draft'"
              clear-icon="mdi-close-circle"
              color="secondary"
              :readonly="data.status != 'draft'"
              height="44"
            ></v-text-field>
            <div class="d-flex mt-n3 align-center">
              <div class="h11">Description<span class="error--text">*</span></div>
              <v-spacer></v-spacer>
              <div class="text--text" style="font-size: 9px">Up to 5000 symb.</div>
            </div>
            <v-textarea
              v-model="item.description"
              :error-messages="descErrors"
              placeholder="Enter description lesson"
              outlined
              dense
              counter
              :clearable="data.status == 'draft'"
              clear-icon="mdi-close-circle"
              color="secondary"
              auto-grow
              :readonly="data.status != 'draft'"
              maxlength="5000"
            ></v-textarea>
            <div class="d-flex align-center" v-if="data.status == 'draft'">
              <v-btn v-if="i != 0 || item.id" @click="deleteLesson(i, item)" text width="100" height="34" color="error" class="mt-2">
                <img src="@/assets/icon/delete.svg" class="mr-1" />Remove
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="item.id ? saveLessons(item) : addLessons(item)" height="34" width="100" color="primary">SAVE</v-btn>
            </div>
          </v-card>
          <v-btn
            :disabled="!!data.lessons.find((item) => item.number == 0)"
            v-if="data.lessons.length < 100 && data.status == 'draft'"
            @click="newLesson(i)"
            height="34"
            block
            color="primary"
          >
            <v-icon left>mdi-plus</v-icon> ADD LESSON
          </v-btn>
        </div>
      </div>
    </div>
    <v-dialog v-model="modalLink" width="568">
      <v-card class="pa-6">
        <div class="d-flex justify-space-between align-center mb-4">
          <div class="h2">Add link</div>
          <v-icon class="black--text" @click="modalLink = false">mdi-close</v-icon>
        </div>
        <div class="h11">Link</div>
        <v-text-field
          class="field44"
          v-model="link"
          :error-messages="linkErrors"
          placeholder="http//..."
          outlined
          dense
          clearable
          clear-icon="mdi-close-circle"
          color="secondary"
          height="44"
        ></v-text-field>
        <div class="text-end">
          <v-btn :disabled="!link" @click="saveMedia" height="34" width="140" color="primary">APPLY</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalMedia" width="568">
      <v-card class="pa-6">
        <div class="d-flex justify-space-between align-center mb-4">
          <div class="h2">Add media</div>
          <v-icon class="black--text" @click="modalMedia = false">mdi-close</v-icon>
        </div>
        <div class="link" @click="addMedia">
          <v-card v-if="!image" height="180" flat tile class="accent pa-3 mb-5 d-flex align-center justify-center">
            <img src="@/assets/img/empty-img.svg" />
          </v-card>
          <img v-else-if="file.type.substr(0, 5) == 'image'" class="d-block mb-5 mx-auto" height="180" :src="image" />
          <v-card tile flat v-else-if="file.type.substr(0, 5) == 'video'" class="text-center mb-5">
            <video controls height="180">
              <source :src="image" />
            </video>
          </v-card>
          <v-card v-else height="180" flat tile class="accent pa-3 mb-5 d-flex align-center justify-center">
            <img height="80" src="@/assets/img/empty-file.svg" />
          </v-card>
        </div>
        <div class="h11">Title</div>
        <v-text-field
          class="field44"
          v-model="title"
          :error-messages="titleErrors"
          placeholder="Title"
          outlined
          dense
          clearable
          clear-icon="mdi-close-circle"
          color="secondary"
          height="44"
        ></v-text-field>
        <div class="h11 mt-n3">Description</div>
        <v-textarea
          v-model="description"
          :error-messages="descErrors"
          placeholder="Description"
          outlined
          dense
          counter
          clearable
          clear-icon="mdi-close-circle"
          color="secondary"
          auto-grow
          maxlength="600"
        ></v-textarea>
        <div class="text-end">
          <v-btn @click="saveMedia" height="34" width="140" color="primary">APPLY</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="modalEdit" width="568">
      <v-card class="pa-6">
        <div class="d-flex justify-space-between align-center mb-4">
          <div class="h2">Edit {{ objectData.type }}</div>
          <v-icon class="black--text" @click="modalEdit = false">mdi-close</v-icon>
        </div>
        <v-card v-if="!objectData.url" height="180" flat tile class="accent pa-3 mb-5 d-flex align-center justify-center">
          <img height="80" src="@/assets/icon/img.svg" />
        </v-card>
        <v-img class="me-3 d-flex align-end" height="180" v-else-if="objectData.type == 'video'" :src="objectData.preview_url"></v-img>
        <div v-else-if="objectData.type == 'pdf'" class="me-3">
          <v-img v-if="objectData.picture" class="d-flex align-end" height="180" :src="objectData.picture"></v-img>
          <v-card v-else tile flat width="66" height="50" color="#eaeafb" class="d-flex align-center justify-center">
            <img class="d-block" src="@/assets/img/empty-file.svg" />
          </v-card>
        </div>
        <v-img class="me-3 d-flex align-end" height="180" v-else :src="objectData.url"></v-img>
        <div class="h11">Title</div>
        <v-text-field
          class="field44"
          v-model="objectData.title"
          :error-messages="titleErrors"
          placeholder="Title"
          outlined
          dense
          clearable
          clear-icon="mdi-close-circle"
          color="secondary"
          height="44"
        ></v-text-field>
        <div class="h11 mt-n3">Description</div>
        <v-textarea
          v-model="objectData.description"
          :error-messages="descErrors"
          placeholder="Description"
          outlined
          dense
          counter
          clearable
          clear-icon="mdi-close-circle"
          color="secondary"
          auto-grow
          maxlength="600"
        ></v-textarea>
        <div class="d-flex flex-wrap">
          <v-btn @click="deleteMedia" text width="100" height="34" color="error">
            <img src="@/assets/icon/delete.svg" class="mr-1" />Remove
          </v-btn>
          <v-spacer></v-spacer>
          <div class="text-end">
            <v-btn @click="editMedia" height="34" width="140" color="primary">SAVE</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="modalDraft" width="568">
      <v-card class="pa-6">
        <div class="d-flex justify-space-between align-center mb-4">
          <div class="h2">Please confirm the course activation</div>
          <v-icon class="black--text" @click="(modalDraft = false), (switch1 = false)">mdi-close</v-icon>
        </div>
        <div class="h6 mb-3">
          The active course will be visible to the students, but you will not be able to edit course details or lessons. You can still edit
          the course attachments and options
        </div>
        <div class="text-right">
          <v-btn @click="(modalDraft = false), (switch1 = false)" text width="140" height="34">Cancel</v-btn>
          <v-btn @click="requestSwitch" class="primary ms-3" width="140" height="34">APPLY</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="modalActive" width="568">
      <v-card class="pa-6">
        <div class="d-flex justify-space-between align-center mb-4">
          <div class="h2">Please confirm the course activation</div>
          <v-icon class="black--text" @click="(modalActive = false), (switch1 = false)">mdi-close</v-icon>
        </div>
        <div class="h6 mb-3">The active course will be visible to the students. You can still edit the course attachments and options</div>
        <div class="text-right">
          <v-btn @click="(modalActive = false), (switch1 = false)" text width="140" height="34">Cancel</v-btn>
          <v-btn @click="requestSwitch" class="primary ms-3" width="140" height="34">APPLY</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="modalDeactive" width="568">
      <v-card class="pa-6">
        <div class="d-flex justify-space-between align-center mb-4">
          <div class="h2">Please confirm the course deactivation</div>
          <v-icon class="black--text" @click="(modalDeactive = false), (switch1 = true)">mdi-close</v-icon>
        </div>
        <div class="h6 mb-3">
          The deactivated course will not be visible to the students. The students that already purchased the course will continue to have
          the access to the course
        </div>
        <div class="text-right">
          <v-btn @click="(modalDeactive = false), (switch1 = true)" text width="140" height="34">Cancel</v-btn>
          <v-btn @click="requestSwitch" class="primary ms-3" width="140" height="34">APPLY</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteModalOption" width="568">
      <v-card class="pa-6">
        <div class="d-flex justify-space-between align-center mb-4">
          <div class="h2">Remove option</div>
          <v-icon class="black--text" @click="deleteModalOption = false">mdi-close</v-icon>
        </div>
        <div class="h6 mb-3">Are you sure you want to delete the option {{ objectData.title }}?</div>
        <div class="text-right">
          <v-btn @click="deleteModalOption = false" text width="140" height="34">Cancel</v-btn>
          <v-btn @click="deleteOptionRequset" class="primary ms-3" width="140" height="34">APPLY</v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteModalLesson" width="568">
      <v-card class="pa-6">
        <div class="d-flex justify-space-between align-center mb-4">
          <div class="h2">Remove lesson</div>
          <v-icon class="black--text" @click="deleteModalLesson = false">mdi-close</v-icon>
        </div>
        <div class="h6 mb-3">Are you sure you want to delete the lesson {{ objectData.title }}?</div>
        <div class="text-right">
          <v-btn @click="deleteModalLesson = false" text width="140" height="34">Cancel</v-btn>
          <v-btn @click="deleteLessonRequset" class="primary ms-3" width="140" height="34">APPLY</v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      switch1: true,
      modalDraft: false,
      modalActive: false,
      modalDeactive: false,
      modalLink: false,
      modalMedia: false,
      modalEdit: false,
      deleteModalOption: false,
      deleteModalLesson: false,
      objectData: {},
      key: 1,
      levelList: ['beginner', 'intermediate', 'advanced', 'proficient'],
      durationList: [15, 30, 45, 60, 75, 90],
      titleOption: '',
      lesson: '',
      type: '',
      typeList: ['trial', 'regular'],
      discountPercent: '',
      link: '',
      title: '',
      description: '',
      image: '',
      file: '',
      error: [],
    };
  },
  mounted() {
    this.$store.dispatch('getCoursesItem');
    this.$store.dispatch('getCategories');
    this.dragDrop();
  },
  methods: {
    notifi(btn) {
      if (btn == 'updateCourse') {
        this.$store.dispatch('setMsg', 'Course is updated successfully');
        this.$store.dispatch('setColor', 'success');
      } else if (btn == 'updateOption') {
        this.$store.dispatch('setMsg', 'Options is updated successfully');
        this.$store.dispatch('setColor', 'success');
      } else if (btn == 'updateLesson') {
        this.$store.dispatch('setMsg', 'Lesson is updated successfully');
        this.$store.dispatch('setColor', 'success');
      } else if (btn == 'saveLesson') {
        this.$store.dispatch('setMsg', 'Lesson is saved successfully');
        this.$store.dispatch('setColor', 'success');
      } else if (btn == 'maxCourse') {
        this.$store.dispatch('setMsg', 'Sorry, but maximum number of courses reached (20 is max)');
        this.$store.dispatch('setColor', 'error');
      } else if (btn == 'maxOption') {
        this.$store.dispatch('setMsg', 'Sorry, but maximum number of options reached (10 is max)');
        this.$store.dispatch('setColor', 'error');
      }
    },
    addMedia() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*,video/*,application/pdf');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            self.image = e.target.result;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    openModal(item) {
      this.objectData = item;
      this.modalEdit = true;
    },
    async updateCourse() {
      this.error = [];
      const data = new Object();
      data.title = this.data.title;
      data.short_description = this.data.short_description;
      data.description = this.data.description;
      data.category = this.data.category.id;
      data.level = this.data.level;
      data.lesson_duration_minutes = this.data.lesson_duration_minutes;
      await this.$store
        .dispatch('updateCoursesItem', data)
        .then(() => {
          this.notifi('updateCourse');
        })
        .catch((e) => {
          this.error = e.response.data.error;
          if (this.error.find((item) => item == 'courses__max_count')) {
            this.notifi('maxCourse');
          }
        });
    },
    async saveOption() {
      this.error = [];
      const items = this.data.options.length;
      const array = this.data.options;
      for (let i = 0; i < items; i++) {
        const data = new Object();
        data.title = array[i].title;
        data.lessons = array[i].lessons;
        data.type = array[i].type;
        data.price = (array[i].priceValue * 100).toFixed();
        data.discount_percent = array[i].discount_percent;
        if (array[i].id) {
          await this.$store
            .dispatch('updateOptionsCoursesItem', { id: array[i].id, data: data })
            .then(() => {
              this.notifi('updateOption');
            })
            .catch((e) => {
              this.error = e.response.data.error;
              if (this.error.find((item) => item == 'courses__max_count')) {
                this.notifi('maxCourse');
              }
            });
        } else {
          await this.$store
            .dispatch('addOptionsCoursesItem', data)
            .then(() => {
              this.notifi('updateOption');
            })
            .catch((e) => {
              this.error = e.response.data.error;
              if (this.error.find((item) => item == 'options__max_count')) {
                this.notifi('maxOption');
              }
            });
        }
      }
    },
    async saveLessons(item) {
      this.error = [];
      await this.$store
        .dispatch('updateLessonsCoursesItem', { id: item.id, data: item })
        .then(() => {
          this.notifi('updateLesson');
          this.key++;
          setTimeout(() => {
            this.dragDrop();
          }, 300);
        })
        .catch((e) => {
          this.error = e.response.data.error;
          if (this.error.find((item) => item == 'courses__max_count')) {
            this.notifi('maxCourse');
          }
        });
    },
    async addLessons(item) {
      this.error = [];
      const data = new Object();
      data.number = this.data.lessons.indexOf(item) + 1;
      data.title = item.title;
      data.description = item.description;
      await this.$store
        .dispatch('addLessonsCoursesItem', data)
        .then(() => {
          this.notifi('saveLesson');
          this.key++;
          setTimeout(() => {
            this.dragDrop();
          }, 300);
        })
        .catch((e) => {
          this.error = e.response.data.error;
          if (this.error.find((item) => item == 'options__max_count')) {
            this.notifi('maxOption');
          }
        });
    },
    activate(e) {
      if (this.data.status == 'draft') {
        this.modalDraft = true;
      } else if (e) {
        this.modalActive = true;
      } else {
        this.modalDeactive = true;
      }
    },
    async requestSwitch() {
      if (this.switch1) {
        await this.$store.dispatch('activateCoursesItem').then(() => {
          this.modalActive = false;
          this.modalDraft = false;
        });
      } else {
        await this.$store.dispatch('deactivateCoursesItem').then(() => {
          this.modalDeactive = false;
        });
      }
    },
    newItem() {
      this.data.options.push({ number: 0, title: '', lessons: '', type: '', price: '', discount_percent: '' });
    },
    newLesson(i) {
      this.data.lessons.splice(i + 1, 0, { number: 0, title: '', description: '' });
    },
    async deleteOption(i, item) {
      if (item.id) {
        this.deleteModalOption = true;
        this.objectData = item;
      } else {
        this.data.options.splice(i, 1);
      }
    },
    async deleteOptionRequset() {
      await this.$store.dispatch('deleteOptionsCoursesItem', this.objectData.id).then(() => {
        this.deleteModalOption = false;
      });
    },
    async deleteLesson(i, item) {
      if (item.id) {
        this.deleteModalLesson = true;
        this.objectData = item;
      } else {
        this.data.lessons.splice(i, 1);
      }
    },
    async deleteLessonRequset() {
      await this.$store.dispatch('deleteLessonsCoursesItem', this.objectData.id).then(() => {
        this.deleteModalLesson = false;
        this.key++;
        setTimeout(() => {
          this.dragDrop();
        }, 300);
      });
    },
    async saveMedia() {
      this.error = [];
      const formData = new FormData();
      this.link ? (formData.append('link', this.link), formData.append('type', 'link')) : '';
      this.title ? formData.append('title', this.title) : '';
      this.description ? formData.append('description', this.description) : '';
      if (this.file) {
        this.file.type.substr(0, 5) == 'image' ? (formData.append('picture', this.file), formData.append('type', 'picture')) : '';
        this.file.type.substr(0, 5) == 'video' ? (formData.append('video', this.file), formData.append('type', 'video')) : '';
        this.file.type == 'application/pdf' ? (formData.append('pdf', this.file), formData.append('type', 'pdf')) : '';
      }
      await this.$store
        .dispatch('addAttachmentsCoursesItem', formData)
        .then(() => {
          this.modalLink = false;
          this.modalMedia = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async editMedia() {
      this.error = [];
      const data = new Object();
      data.title = this.objectData.title;
      data.description = this.objectData.description;
      await this.$store
        .dispatch('updateAttachmentsCoursesItem', { id: this.objectData.id, data: data })
        .then(() => {
          this.modalEdit = false;
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async deleteMedia() {
      await this.$store.dispatch('deleteAttachmentsCoursesItem', this.objectData.id).then(() => {
        this.modalEdit = false;
      });
    },
    dragDrop() {
      const tasksListElement = this.$refs.list;
      tasksListElement.addEventListener('dragstart', (evt) => {
        evt.target.classList.add('active-item');
      });
      tasksListElement.addEventListener('dragend', (evt) => {
        evt.target.classList.remove('active-item');
        const n = evt.target.querySelector('#number').innerHTML;
        const data = Object.assign(
          {},
          this.data.lessons.find((item) => item.number == n)
        );
        data.number = [...document.querySelectorAll('#number')].map((item) => item.innerHTML).indexOf(n) + 1;
        this.saveLessons(data);
      });
      tasksListElement.addEventListener('dragover', (evt) => {
        // Разрешаем сбрасывать элементы в эту область
        evt.preventDefault();
        // Находим перемещаемый элемент
        const activeElement = tasksListElement.querySelector('.active-item');
        // Находим элемент, над которым в данный момент находится курсор
        const currentElement = evt.target;
        // Проверяем, что событие сработало:
        // 1. не на том элементе, который мы перемещаем,
        // 2. именно на элементе списка
        const isMoveable = activeElement !== currentElement && this.$refs.item;
        // Если нет, прерываем выполнение функции
        if (!isMoveable) {
          return;
        }
        // Находим элемент, перед которым будем вставлять
        const nextElement = currentElement === activeElement.nextElementSibling ? currentElement.nextElementSibling : currentElement;
        // Вставляем activeElement перед nextElement
        tasksListElement.insertBefore(activeElement, nextElement);
      });
    },
  },
  watch: {
    data() {
      this.data.status == 'active' ? (this.switch1 = true) : (this.switch1 = false);
      if (!this.data.options.length) {
        this.newItem();
      } else {
        for (let i = 0; i < this.data.options.length; i++) {
          this.data.options[i].priceValue = this.data.options[i].price / 100;
        }
      }
      if (!this.data.lessons.length) {
        this.newLesson();
      }
    },
    modalLink() {
      this.link = '';
      this.error = [];
    },
    modalMedia() {
      this.file = '';
      this.image = '';
      this.title = '';
      this.description = '';
      this.error = [];
    },
  },
  computed: {
    data() {
      return this.$store.getters.coursesItem;
    },
    categoriesData() {
      return this.$store.getters.categoriesList;
    },
    titleErrors() {
      const errors = [];
      this.error.find((item) => item == 'title__required') && errors.push('Please enter title course');
      this.error.find((item) => item == 'title__invalid') && errors.push('Provided title is not valid (max 50 symbols)');
      return errors;
    },
    shortErrors() {
      const errors = [];
      this.error.find((item) => item == 'short_description__required') && errors.push('Please enter short description');
      this.error.find((item) => item == 'short_description__invalid') &&
        errors.push('Provided short description is not valid (max 150 symbols)');
      return errors;
    },
    descErrors() {
      const errors = [];
      this.error.find((item) => item == 'description__required') && errors.push('Please enter description');
      this.error.find((item) => item == 'description__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
    categoryErrors() {
      const errors = [];
      this.error.find((item) => item == 'category__required') && errors.push('Please enter category');
      this.error.find((item) => item == 'category__invalid') && errors.push('Provided category is not valid');
      return errors;
    },
    levelErrors() {
      const errors = [];
      this.error.find((item) => item == 'level__required') && errors.push('Please enter level');
      this.error.find((item) => item == 'level__required') && errors.push('Provided level is not valid');
      return errors;
    },
    durationErrors() {
      const errors = [];
      this.error.find((item) => item == 'lesson_duration_minutes__required') && errors.push('Please enter duration');
      this.error.find((item) => item == 'lesson_duration_minutes__invalid') && errors.push('Provided duration is not valid');
      return errors;
    },
    titleOptionErrors() {
      const errors = [];
      this.error.find((item) => item == 'title__required') && errors.push('Please enter title option');
      this.error.find((item) => item == 'title__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    lessonsOptionErrors() {
      const errors = [];
      this.error.find((item) => item == 'lessons__required') && errors.push('Please enter lessons');
      this.error.find((item) => item == 'lessons__invalid') && errors.push('Provided lessons is not valid');
      return errors;
    },
    typeOptionErrors() {
      const errors = [];
      this.error.find((item) => item == 'type__required') && errors.push('Please enter type');
      this.error.find((item) => item == 'type__invalid') && errors.push('Provided type is not valid');
      return errors;
    },
    discountOptionErrors() {
      const errors = [];
      this.error.find((item) => item == 'discount__required') && errors.push('Please enter discount');
      this.error.find((item) => item == 'discount_percent') && errors.push('Provided discount is not valid');
      return errors;
    },
    priceOptionErrors() {
      const errors = [];
      this.error.find((item) => item == 'price__required') && errors.push('Please enter price');
      this.error.find((item) => item == 'price__invalid') && errors.push('Provided price is not valid');
      return errors;
    },
    linkErrors() {
      const errors = [];
      this.error.find((item) => item == 'body__required') && errors.push('Please enter link');
      this.error.find((item) => item == 'link__invalid') && errors.push('Provided link is not valid');
      return errors;
    },
  },
  destroyed() {
    this.$store.dispatch('setCoursesItem', { options: [], lessons: [], attachments: [] });
    this.$store.dispatch('setCategories', []);
  },
};
</script>

<style lang="scss">
.course-edit {
  &-header {
    background-image: url(../assets/img/header-img.svg);
    background-repeat: repeat !important;
  }
  .wrap {
    max-width: 568px;
  }
  .remove-option {
    position: absolute;
    right: 10px;
    margin-top: 24px;
    .v-icon:hover {
      color: red !important;
    }
  }
  .btn-link {
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    padding: 14px 0;
    border-radius: 0 4px 4px 0;
    width: 46px;
    height: 44px;
    background: rgba(0, 0, 255, 0.11);
    color: #0000ff;
  }
}
</style>
